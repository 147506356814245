import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import chatImageES from "./images/chatImageES.png";
import chatImagePT from "./images/chatImagePT.png";
import chatImageEN from "./images/chatImageEN.png";

export default function Chatbot() {
	const [t, i18n] = useTranslation("global");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	let chatImage = chatImageES;

	switch (i18n.language) {
		case "pt":
			chatImage = chatImagePT;
			break;
		case "en":
			chatImage = chatImageEN;
			break;
		default:
			chatImage = chatImageES;
			break;
	}

	return (
		<div>
			<div className="action-btn">
				<a href={t("ChatBot.urlChat")}>
					<img src={chatImage} height="50px" width="200px" />
				</a>
			</div>
		</div>
	);
}
