const bases = [
	{ name: "Alicante, Polígono Vallonga" },
	{ name: "A Coruña, C/ José Espronceda" },
	{ name: "Asturias, Poligono Industrial Porceyo" },
	{ name: "Barcelona, Viladecans" },
	{ name: "Barcelona, Barberà del Vallès" },
	{ name: "Cádiz, Calle Académico Juan Luis Roche" },
	{ name: "Córdoba, Santa Cruz" },
	{ name: "Gran Canaria, Agüimes" },
	{ name: "Granada, Chauchina" },
	{ name: "Madrid, San Fernando" },
	{ name: "Madrid, Alcobendas" },
	{ name: "Madrid, Getafe" },
	{ name: "Málaga, Polígono Industrial San Luis" },
	{ name: "Málaga, Polígono Industrial Guadalhorce" },
	{ name: "Mallorca, Polígono Son Noguera" },
	{ name: "Murcia, Polígono la Alcantarilla" },
	{ name: "Sevilla, Santa Clara" },
	{ name: "Tenerife, Polígono Los Majuelos" },
	{ name: "Toledo, Calle Río Jarama" },
	{ name: "Valencia, Manises" },
	{ name: "Valladolid, Calle Cobalto" },
	{ name: "Vizcaya, Polígono Lapatza" },
	{ name: "Zaragoza, Polígono Industrial Molino del Pilar" },
	{ name: "Lisboa, Odivelas" },
];

export default bases;
