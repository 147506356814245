import React from 'react';
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import logoBlanco from './images/Logo-blanco.png';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

function typeFunc(a) {
  if (a.key === 'line') {
    return 'right';
  } else if (a.key === 'button') {
    return 'bottom';
  }
  return 'left';
}

export default function Banner({ onEnterChange }) {
  const [t, i18n] = useTranslation('global');

  return (
    <section className="page banner-wrapper">

      <ScrollElement
        className="page"
        id="banner"
        onChange={({ mode }) => onEnterChange(mode)}
        playScale={0.9}
      >
        <Row gutter={24} className="banner-header">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
            <QueueAnim
              className="banner-text-wrapper"
              type={typeFunc}
              delay={300}
              key="banner"
            >
              <div className="banner-text">
                <h2 key="h2" className="title-banner">
                  {t('Banner.title-banner1')}{' '}
                  <p> {t('Banner.title-banner2')}</p>!
                </h2>
                <p key="content" className="content-banner">
                  {t('Banner.content-banner1')}
                  <br />
                  {t('Banner.content-banner2')}
                </p>
              </div>
            </QueueAnim>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
            <img className="img-banner" src={logoBlanco} alt="driver" />
          </Col>
        </Row>
      </ScrollElement>
    </section>
  );
}
Banner.propTypes = {
  onEnterChange: PropTypes.func,
};
