import React from 'react';
import { enquireScreen } from 'enquire-js';
import Header from './Header';
import Banner from './Banner';
import Services from './Services';
import Hubs from './Hubs';
import Fleet from './Fleet';
import Platform from './Platform';
import Footer from './Footer';
import Chatbot from './Chatbot';
import { withTranslation } from "react-i18next";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});
class Home extends React.PureComponent {
  state = {
    isFirstScreen: true,
    isMobile,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  onEnterChange = (mode) => {
    this.setState({
      isFirstScreen: mode === 'enter',
    });
  };

  render() {
    const Menu = withTranslation()(Header);
    return [
      <Menu
        key="header"
        isFirstScreen={this.state.isFirstScreen}
        isMobile={this.state.isMobile}
      />,
      <Banner key="banner" onEnterChange={this.onEnterChange} />,
      <Services key="Services" isMobile={this.state.isMobile} />,
      <Hubs key="Hubs" isMobile={this.state.isMobile} />,
      // <Fleet key="Fleet" isMobile={this.state.isMobile} />,
      // <Platform key="Platform" isMobile={this.state.isMobile} />,
      <Footer key="footer" />,
      <Chatbot key="chatbot" isMobile={this.state.isMobile}/>
    ];
  }
}

export default Home;
