import React from 'react';
import Tracking from '../Home/Tracking';
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import logoBlanco from '../Home/images/Logo-blanco.png';

function typeFunc(a) {
  if (a.key === 'line') {
    return 'right';
  } else if (a.key === 'button') {
    return 'bottom';
  }
  return 'left';
}

export default function Portal() {
  return (
    <section className="page banner-wrapper">
      <ScrollElement
        className="page"
        id="banner"
        playScale={0.9}
      >
        <Row gutter={24} className="banner-header">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
            <QueueAnim
              className="banner-text-wrapper"
              type={typeFunc}
              delay={300}
              key="banner"
            >
              <div className="banner-text">
                <h2 key="h2" className="title-banner">
                <p>Locate your shipment</p> <br/>
                by entering your tracking number
                </h2>
                <Tracking style={{width: '200px'}}/>
              </div>
            </QueueAnim>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
            <img className="img-banner" src={logoBlanco} alt="driver" />
          </Col>
        </Row>
      </ScrollElement>
    </section>
  );
}

Portal.propTypes = {
  onEnterChange: PropTypes.func,
};
