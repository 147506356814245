import { Button } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Home/Footer';

const script = document.createElement('script');

export default function CookiesConsent() {
  const [cookie] = useState({
    checked: false,
  });

  const [show, setShow] = useState(true);

  const handleClick = () => {
    cookie.checked = true;
    setShow(!show);
    if (cookie.checked === true) {
      script.id = 'CookieDeclaration';
      script.type = 'text/javascript';
      script.src =
        'https://consent.cookiebot.com/81b48914-7d4a-454b-97fa-d954c5c73897/cd.js';
      script.async = true;
      document.body.appendChild(script);
    }
  };

  const handleClickClose = () => {
    cookie.checked = false;
    if (cookie.checked === false) {
      const cookieDelete = document.querySelector('.CookieDeclaration');
      cookieDelete.remove();
    }
  };

  return (
    <div>
        <div id="consent" className="cookies-consent">
          <Button style={{margin: '10px'}} type="primary" onClick={handleClick}>Modificar Consentimiento</Button>
          <Button style={{margin: '10px'}} onClick={handleClickClose}><Link to="/">Volver a Página de Inicio</Link></Button>
        </div>
        <Footer/>
    </div>
  );
}
