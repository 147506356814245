import React from 'react';
import './style';
import 'antd/dist/antd.css';
import { Route, Switch } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Privacidad from '../src/LPD/Pol-Priv.jsx';
import PolCookies from '../src/LPD/Cookies.jsx';
import Aviso from '../src/LPD/Aviso-legal.jsx';
import CookiesConsent from '../src/LPD/CookiesConsent.jsx';
import Home from './Home/Home';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import Portal from './Portal/Portal';
import Img from './Home/Img-prev';
const domainGroupId = '81b48914-7d4a-454b-97fa-d954c5c73897';

function App() {
  
  return (
    <div>
      <CookieBot domainGroupId={domainGroupId} language="ES" />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/politica-privacidad" component={Privacidad} />
        <Route exact path="/aviso-legal" component={Aviso} />
        <Route exact path="/politica-cookies" component={PolCookies} />
        <Route exact path="/cookies-consent" component={CookiesConsent} />
        <Route exact path="/shipmenttracking" component={Portal} />
        <Route exact path="/img-prev" component={Img} />
      </Switch>
    </div>
  );
}

export default App;
