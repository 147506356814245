import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function Tracking() {
  const [t, i18n] = useTranslation('global');
  const [search, setSearch] = useState('');

  const url = `https://play.ecoscooting.com/tracking/${search}`;

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="input-search">
      <Input
        placeholder={t('Tracking.placeholder')}
        allowClear
        onChange={handleChange}
        style={{ width: '80%' }}
      />
      <a className="button-search" href={url}>
        <SearchOutlined />
      </a>
    </div>
  );
}
