import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import apple from "./images/logo-apple.png";
import google from "./images/google-play.png";
import { useTranslation } from "react-i18next";
import { EnvironmentFilled } from "@ant-design/icons";
import bases from "../Bases.js";
const mid = Math.floor(bases.length / 2);
const bases1 = bases.slice(0, mid);
const bases2 = bases.slice(mid);

function Footer() {
	const [t, i18n] = useTranslation("global");

	return (
		<footer id="footer" className="dark">
			<div className="footer-wrap">
				<Row>
					<Col lg={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>
								{t("Footer.titleEcos")} <br /> <a href="/">{t("Footer.web")}</a>{" "}
								<br />
								{t("Footer.allRights")}
							</h2>
							<ul>
								<li>
									<Link to="/aviso-legal">{t("Footer.aviso-legal-url")}</Link>
								</li>
								<li>
									<Link to="/politica-privacidad">
										{t("Footer.politica-privacidad-url")}
									</Link>
								</li>
								<li>
									<Link to="/politica-cookies">
										{t("Footer.politica-cookies-url")}
									</Link>
								</li>
							</ul>
						</div>
					</Col>
					<Col lg={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>{t("Footer.contact")}</h2>
							<ul>
								<li>
									<strong>E-mail:</strong>{" "}
									<a href="mailto:{t('Customer.email')}">
										{t("Customer.email")}
									</a>
								</li>
								<li>
									<strong>{t("Footer.scheduleTitle")}:</strong>{" "}
									<span>{t("Footer.scheduleText")}</span>
								</li>
								{t("Customer.phone").length > 1 ? (
									<li>
										<strong>{t("Footer.phone")}: </strong> {t("Customer.phone")}
									</li>
								) : (
									""
								)}
								{/* {i18n.language !== 'pt' ? (
                  <li>
                    <a href="{t('Footer.aten-client')}">
                      {t('Footer.aten-client')}
                    </a>
                  </li>
                ) : (
                  ''
                )} */}
								<li>
									<a href={t("ChatBot.urlChat")}>{t("Footer.chat")}</a>
								</li>
							</ul>
						</div>
					</Col>
					<Col lg={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>{t("Footer.ubicationsTitle")}</h2>
							<ul className="footer-address">
								{bases1.map((location, index) => (
									<li key={index}>
										{" "}
										<EnvironmentFilled /> {location.name}
									</li>
								))}
							</ul>
						</div>
					</Col>
					<Col lg={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>{t("Footer.ubicationsTitle")}</h2>
							<ul className="footer-address">
								{bases2.map((location, index) => (
									<li key={index}>
										{" "}
										<EnvironmentFilled /> {location.name}
									</li>
								))}
							</ul>
						</div>
					</Col>
					{/* <Col lg={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>{t("Footer.download-app")}</h2>
							<Row justify="center">
								<Col xs={24} sm={24} md={12} lg={12} xl={12} span={8}>
									<a href="https://play.google.com/store/apps/details?id=com.ecoscooting.appecoscooting&gl=ES">
										<img
											style={{ width: "205px" }}
											src={google}
											alt="Play Store"
										/>
									</a>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12} xl={12} span={8}>
									<a href="https://apps.apple.com/do/app/appecoscooting/id1426530603">
										<img
											style={{ width: "200px" }}
											src={apple}
											alt="Apple Store"
										/>
									</a>
								</Col>
							</Row>
						</div>
					</Col> */}
				</Row>
			</div>
		</footer>
	);
}

export default Footer;
