import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import Logo from "./images/logo.jpg";

const htmlContent = `
<div>
	<p
		class="MsoNormal"
		align="center"
		style="text-align: center; line-height: 125%"
	>
		<b style="mso-bidi-font-weight: normal">
			<u>POLÍTICA DE PRIVACIDAD 2024</u>
		</b>
	</p>

	<p
		class="MsoNormal"
		align="center"
		style="text-align: center; line-height: 125%"
	>
		<b style="mso-bidi-font-weight: normal">ECOSCOOTING DELIVERY SL</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		Última actualización 15 de febrero de 2024
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<span style="color: black; background: white">
			ECOSCOOTING DELIVERY SL
		</span>
		<span
			style="
				mso-fareast-font-family: 'Times New Roman';
				mso-fareast-theme-font: major-fareast;
			"
		>
			(en adelante, ECOSCOOTING) como Responsable del Tratamiento y de este
			sitio web, de conformidad con lo que dispone el
		</span>
		Reglamento (UE) 2016/679, General de Protección de Datos y de la Ley
		Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y
		garantía de los derechos digitales, pone a su disposición la presente
		política de privacidad con la finalidad de informarle, de forma detallada,
		sobre cómo tratamos sus datos personales y protegemos su privacidad y la
		información que nos proporciona.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		En esta política de privacidad le explicamos
		<span class="SpellE">cuales</span> son sus derechos en cuanto a su
		información personal y el modo de ejercerlos. Adicionalmente, en caso de que
		necesite dirigirse a
		<span class="GramE">
			la<span style="mso-spacerun: yes">  </span>autoridad
		</span>
		competente en materia de protección de datos, le proporcionamos el contacto.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<table
		class="MsoTableGrid"
		border="1"
		cellspacing="0"
		cellpadding="0"
		style="
			border-collapse: collapse;
			border: none;
			mso-border-alt: solid windowtext 0.5pt;
			mso-yfti-tbllook: 1184;
			mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
		"
	>
		<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
			<td
				width="189"
				valign="top"
				style="
					width: 141.5pt;
					border: solid windowtext 1pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					<b style="mso-bidi-font-weight: normal">Nosotros</b>, los responsables
					del tratamiento de sus datos
				</p>
			</td>
			<td
				width="377"
				valign="top"
				style="
					width: 282.9pt;
					border: solid windowtext 1pt;
					border-left: none;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p
					class="MsoNormal"
					align="left"
					style="margin: 0cm; text-align: left; line-height: normal"
				>
					<b style="mso-bidi-font-weight: normal">
						<span lang="EN-US" style="mso-ansi-language: EN-US">
							Identidad:
						</span>
					</b>
					<span lang="EN-US" style="mso-ansi-language: EN-US">
						&nbsp;ECOSCOOTING DELIVERY
						<span style="color: black; background: white">SL</span>
					</span>
					<span
						lang="EN-US"
						style="mso-ansi-language: EN-US; mso-fareast-language: ES-TRAD"
					></span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="margin: 0cm; text-align: left; line-height: normal"
				>
					<b style="mso-bidi-font-weight: normal">
						<span lang="EN-US" style="mso-ansi-language: EN-US"> NIF: </span>
					</b>
					<span lang="EN-US" style="mso-ansi-language: EN-US">
						&nbsp;<span class="Ninguno">B65874893</span>
					</span>
					<span
						lang="EN-US"
						style="mso-ansi-language: EN-US; mso-fareast-language: ES-TRAD"
					></span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="margin: 0cm; text-align: left; line-height: normal"
				>
					<b style="mso-bidi-font-weight: normal">Domicilio social:</b>
					&nbsp;
					<span class="Ninguno">
						<span lang="PT" style="mso-ansi-language: PT">
							Ronda de Marcos Ana 6 Nave DC&amp;, 28830, Madrid
						</span>
					</span>
					<span style="mso-fareast-language: ES-TRAD"></span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="margin: 0cm; text-align: left; line-height: normal"
				>
					<b style="mso-bidi-font-weight: normal">Correo electrónico:</b>
					<span class="Ninguno">
						<span
							lang="ES-TRAD"
							style="
								color: black;
								mso-color-alt: windowtext;
								background: white;
								mso-ansi-language: ES-TRAD;
							"
						>
							privacy@ecoscooting.com
						</span>
					</span>
					<span style="mso-fareast-language: ES-TRAD"></span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				></p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 1">
			<td
				width="189"
				valign="top"
				style="
					width: 141.5pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Nuestro <span class="GramE">Delegado</span> de Protección de Datos (<b
						style="mso-bidi-font-weight: normal"
						>DPD</b
					>)
				</p>
			</td>
			<td
				width="377"
				valign="top"
				style="
					width: 282.9pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="margin: 0cm; line-height: normal">
					Si tiene cualquier tipo de consulta, duda o sugerencia con relación a
					cómo usamos sus datos personales puede dirigirse al
					<span class="GramE">
						<b style="mso-bidi-font-weight: normal">Delegado</b>
					</span>
					<b style="mso-bidi-font-weight: normal">de Protección de Datos</b>
					a través de la dirección de correo electrónico:
				</p>
				<p class="MsoNormal" style="margin: 0cm; line-height: normal">
					xavi@aurisadvocats.com
					<span style="mso-fareast-language: ES-TRAD"></span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				></p>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				></p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 2; mso-yfti-lastrow: yes">
			<td
				width="189"
				valign="top"
				style="
					width: 141.5pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p
					class="CuerpoA"
					style="
						text-align: justify;
						line-height: 107%;
						mso-outline-level: 1;
						tab-stops: 35.4pt 70.8pt 106.2pt 141.6pt 177pt 212.4pt 247.8pt
							283.2pt 318.6pt 354pt 389.4pt 424.8pt 456.6pt;
					"
				>
					<span class="Ninguno">
						<span
							lang="ES-TRAD"
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-ansi-language: ES-TRAD;
							"
						>
							Esta política de privacidad es extensible a la empresa:
						</span>
					</span>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-fareast-font-family: 'Trebuchet MS';
							"
						></span>
					</span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					<span style="font-size: 11pt; line-height: 125%"></span>
				</p>
			</td>
			<td
				width="377"
				valign="top"
				style="
					width: 282.9pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p
					class="CuerpoA"
					style="
						text-align: justify;
						line-height: 107%;
						mso-outline-level: 1;
						tab-stops: 35.4pt 70.8pt 106.2pt 141.6pt 177pt 212.4pt 247.8pt
							283.2pt 318.6pt 354pt 389.4pt 424.8pt 456.6pt;
					"
				>
					<span class="Ninguno">
						<span
							lang="ES-TRAD"
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-ansi-language: ES-TRAD;
							"
						>
							ECOXPRESS SOLUCIONES Y SERVICIOS SLU
						</span>
					</span>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-fareast-font-family: 'Trebuchet MS';
							"
						></span>
					</span>
				</p>
				<p
					class="CuerpoA"
					style="
						text-align: justify;
						line-height: 107%;
						mso-outline-level: 1;
						tab-stops: 35.4pt 70.8pt 106.2pt 141.6pt 177pt 212.4pt 247.8pt
							283.2pt 318.6pt 354pt 389.4pt 424.8pt 456.6pt;
					"
				>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
							"
						>
							CIF: B-88035266
						</span>
					</span>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-fareast-font-family: 'Trebuchet MS';
							"
						></span>
					</span>
				</p>
				<p
					class="CuerpoA"
					style="
						text-align: justify;
						line-height: 107%;
						mso-outline-level: 1;
						tab-stops: 35.4pt 70.8pt 106.2pt 141.6pt 177pt 212.4pt 247.8pt
							283.2pt 318.6pt 354pt 389.4pt 424.8pt 456.6pt;
					"
				>
					<span class="Ninguno">
						<span
							lang="ES-TRAD"
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-ansi-language: ES-TRAD;
							"
						>
							Domicilio Social: C/
							<span class="SpellE">Andres</span> Mellado 84
						</span>
					</span>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-fareast-font-family: 'Trebuchet MS';
							"
						></span>
					</span>
				</p>
				<p
					class="CuerpoA"
					style="
						text-align: justify;
						line-height: 107%;
						mso-outline-level: 1;
						tab-stops: 35.4pt 70.8pt 106.2pt 141.6pt 177pt 212.4pt 247.8pt
							283.2pt 318.6pt 354pt 389.4pt 424.8pt 456.6pt;
					"
				>
					<span class="Ninguno">
						<span
							lang="ES-TRAD"
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-ansi-language: ES-TRAD;
							"
						>
							Madrid 28015
						</span>
					</span>
					<span class="Ninguno">
						<span
							style="
								font-size: 12pt;
								line-height: 107%;
								font-family: 'Times New Roman', serif;
								mso-fareast-font-family: 'Trebuchet MS';
								mso-font-kerning: 18pt;
							"
						></span>
					</span>
				</p>
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					<span style="font-size: 11pt; line-height: 125%"></span>
				</p>
			</td>
		</tr>
	</table>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Qué tipo de información sobre Ud. podríamos recoger?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Los datos personales que se recabamos de los usuarios y
		<span class="GramE">clientes,</span> se pueden agrupar según las siguientes
		categorías:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">Datos básicos y de contacto:</b>
		como pueden ser el nombre, los apellidos, el nombre de usuario o
		identificador similar, fecha de nacimiento, género. Incluyendo también su
		dirección de facturación y entrega, email y número de teléfono. También los
		datos del <span class="GramE">carnet</span> de conducir.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b>Datos financieros y económicos:</b> en este grupo quedarían incluidos los
		detalles de pago, devolución y reintegro, así como las transacciones
		comerciales efectuadas con nosotros. Incluyendo datos de verificación de du
		identidad para la aceptación de pagos y las comprobaciones financieras
		pertinentes para poder llevar a cabo las transacciones comerciales.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">
			Datos profesionales y de empleo:
		</b>
		en esta categoría se englobarían sus
		<span class="GramE">
			intereses profesionales y su identidad profesional
		</span>
		publicada online. Por ejemplo, su perfil de LinkedIn.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">Datos técnicos:</b>
		incluyendo la dirección IP, datos de registro, navegador y versión
		utilizados, la zona y uso horarios, tipo de
		<span class="SpellE">plugins</span> instalados en su navegador, sistema
		operativo y demás tecnología utilizada durante el acceso a nuestra
		plataforma.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">
			Datos de su cuenta de usuario:
		</b>
		como el nombre de perfil y contraseña.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">Datos de navegación:</b>
		incluye información relativa a su modo de navegación cuando visita nuestra
		plataforma.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">
			Preferencias de marketing y comunicación:
		</b>
		recabamos sus preferencias para recibir comunicaciones comerciales y
		noticias nuestras, los consentimientos otorgados para ello y el canal de su
		elección
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">
			Imágenes captadas por cámaras de videovigilancia:
		</b>
		aquí se agrupan las imágenes que podrían captarse mediante cámaras de
		videovigilancia en los almacenes si los hubiera.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		<b style="mso-bidi-font-weight: normal">
			<span style="color: black; mso-themecolor: text1">
				Datos de los destinatarios del reparto:
			</span>
		</b>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		>
			Existen dos casuísticas:
		</span>
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="
			margin-left: 54pt;
			mso-add-space: auto;
			line-height: 125%;
			mso-list: l2 level1 lfo6;
		"
	>
		<b>
			<span style="color: black; mso-themecolor: text1">
				Cuando <span class="SpellE">Ecoscooting</span>
				<span class="SpellE">Delivery</span> SL actúa como encargado de
				tratamiento
			</span>
		</b>
		<span style="color: black; mso-themecolor: text1">
			: Para la ejecución de la prestación derivada del cumplimiento del objeto
			del contrato de encargo de tratamiento, el
			<span class="GramE">Responsable</span> de Tratamiento (la empresa
			suministradora de los bienes), pone a disposición del Encargado de
			Tratamiento (<span class="SpellE">Ecoscooting</span>
			<span class="SpellE">Delivery</span> SL) la siguiente información:
			<span class="apple-converted-space">
				&nbsp;Nombre, apellidos, dirección de entrega y teléfono.
			</span>
		</span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="margin-left: 54pt; mso-add-space: auto; line-height: 125%"
	>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		>
			Asimismo, des de <span class="SpellE">Ecoscooting</span> se solicitará al
			destinatario su DNI, tanto para programar la entrega a terceras personas o
			buzón como en el momento de entrega del paquete para poder responder ante
			posibles reclamaciones sobre incidencias en el envío.
		</span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="
			margin-left: 54pt;
			mso-add-space: auto;
			line-height: 125%;
			mso-list: l2 level1 lfo6;
		"
	>
		<b style="mso-bidi-font-weight: normal">
			<span style="color: black; mso-themecolor: text1">
				Cuando <span class="SpellE">Ecoscooting</span>
				<span class="SpellE">Delivery</span> SL actúa como
				<span class="GramE">Responsable</span> de tratamiento:
			</span>
		</b>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		></span>
		<span
			style="
				color: black;
				border: none windowtext 1pt;
				mso-border-alt: none windowtext 0cm;
				padding: 0cm;
				background: white;
			"
		>
			En el marco del cumplimiento del contrato de prestación de servicios con
			la empresa suministradora de los bienes y en estricto apego a la normativa
			de protección de datos, la empresa suministradora de los bienes, en
			calidad de Responsable del Tratamiento, facilita al Responsable
			independiente del tratamiento (
			<span class="SpellE">Ecoscooting</span>
			<span class="SpellE">Delivery</span> SL) la siguiente información: Nombre,
			apellidos, dirección de entrega y teléfono. Todos los datos personales
			mencionados serán tratados y resguardados adecuadamente, conforme a la
			normativa vigente en materia de protección de datos.
			<br />
			Asimismo, <span class="SpellE">Ecoscooting</span> solicitará al
			destinatario su DNI, tanto para programar la entrega a terceras personas o
			buzón como en el momento de entrega del paquete, con el objetivo de
			responder ante posibles reclamaciones relacionadas con incidencias en el
			envío.
		</span>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		></span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="margin-left: 54pt; mso-add-space: auto; line-height: 125%"
	>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		>
			@
		</span>
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="margin-left: 54pt; mso-add-space: auto; line-height: 125%"
	>
		<span
			style="color: black; mso-themecolor: text1; mso-bidi-font-weight: bold"
		></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Nunca recabaremos datos personales especialmente protegidos o considerados
		sensibles.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Cómo recogemos sus datos personales?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Como norma general, la mayor parte de su información personal nos la
		proporciona directamente Ud. ya sea presencialmente, por teléfono, correo,
		formularios web o respondiendo a encuestas. Sin embargo, también podemos
		obtener información de:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		De terceros vinculados con nosotros,
		<span class="GramE">como</span> por ejemplo:
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		De su empleador
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		De <span class="GramE">una tercero</span> que haya recabado previamente su
		consentimiento expreso para ello, como por ejemplo empresas que nos
		contratan para prestar servicios de reparto.
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		De las cookies que habilitamos en nuestra web – Para información ampliada
		sobre el uso de nuestras cookies, puede visitar nuestra política de cookies.
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="line-height: 125%; mso-list: l4 level1 lfo2"
	>
		De nuestros sistemas de acceso a las instalaciones, cuando los hubiere. Como
		por ejemplo serían los registros de entrada y recepción, los sistemas de
		fichado en el caso de empleados, cámaras de videovigilancia, sistemas de
		comunicación y mensajería instantánea, email o redes sociales.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Qué puede ocurrir si no nos facilita su información personal?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Cuando estemos obligados por ley a recoger sus datos personales o, cuando
		éstos resulten imprescindibles para celebrar un contrato con usted, en el
		caso de que no nos quiera facilitar sus datos
		<span class="GramE">
			personales,<span style="mso-spacerun: yes">  </span>es
		</span>
		posible que nos sea imposible servirle.
		<span style="mso-spacerun: yes">  </span>En el caso eventual de que nos
		veamos en la necesidad de cancelar nuestros servicios por ello, le
		notificaremos primero cuando fuere necesario.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal"></b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Con qué finalidad tratamos su información personal?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Adjuntamos una tabla detallada con la finalidad para la que recogemos sus
		datos y la base legal que nos legitima para ello.
		<span style="mso-spacerun: yes"> </span>
	</p>

	<div align="center">
		<table
			class="MsoTableGrid"
			border="1"
			cellspacing="0"
			cellpadding="0"
			style="
				border-collapse: collapse;
				border: none;
				mso-border-alt: solid windowtext 0.5pt;
				mso-yfti-tbllook: 1184;
				mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
			"
		>
			<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="center"
						style="text-align: center; line-height: 125%"
					>
						<b style="mso-bidi-font-weight: normal">
							Finalidad del tratamiento
							<br />
							¿Para qué recogemos su información?
						</b>
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border: solid windowtext 1pt;
						border-left: none;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="center"
						style="text-align: center; line-height: 125%"
					>
						<b style="mso-bidi-font-weight: normal">
							Legitimidad legal <br />
							para tratar su información personal
						</b>
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 1">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Proporcionarle nuestros servicios, aceptar pagos y cobros debidos
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Ejecución contractual
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) En interés legítimo propio (por ejemplo, para gestionar
						eventuales impagados o para responder a posibles reclamaciones sobre
						incidencias en los envíos)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 2">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Registrarse como usuario web o nuevo cliente
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Consentimiento expreso del interesado
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Ejecución contractual
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 3">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Gestionar nuestra relación con nuestros clientes, que incluye:
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Notificarle de cambios en nuestras condiciones de contratación o
						políticas
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Solicitar que respondan una encuesta o valoren nuestros
						productos/servicios
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Ejecución contractual
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Cumplimiento de una obligación legal
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(3) En interés legítimo propio (para actualizar nuestros registros y
						conocer la opinión
						<span class="GramE">
							de<span style="mso-spacerun: yes">  </span>nuestros
						</span>
						clientes a cerca de nuestros servicios)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 4">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Remitir las comunicaciones comerciales, boletines, por cualquier
						canal de comunicación
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Consentimiento expreso del interesado
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) En interés legítimo propio (siempre no haya expresado su deseo a
						dejar de recibir comunicación, ‘
						<span class="SpellE">opt-out</span>’)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 5">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Responder a las consultas y/o proporcionar información requerida por
						el interesado, incluyendo el envío de presupuestos
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Interés legítimo propio
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Ejecución contractual
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(3) Consentimiento del interesado
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(4) Cumplimiento de una obligación legal
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 6">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Gestionar las interactuaciones de los usuarios en nuestras redes
						sociales
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Cumplimiento de una obligación legal (por
						<span class="GramE">ejemplo</span> para eliminar comentarios
						ofensivos, racistas, soeces o injuriosos; mantener un ambiente de
						respeto e integración, preservar la intimidad de menores, etc.)
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Interés legítimo propio (cuando eliminamos publicidad de
						terceros de nuestras redes, por ejemplo)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 7">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p class="MsoNormal" style="line-height: 125%">
						Utilizar datos analíticos para mejorar la experiencia de navegación
						web/ uso de <span class="GramE">app</span>, implementar estrategias
						de marketing y optimizar procesos de contratación mediante el uso de
						cookies.
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Interés legítimo propio
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Consentimiento del interesado (al aceptar el uso de cookies
						analíticas, por ejemplo)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 8">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Administrar y proteger nuestro negocio y nuestra página web. Esto
						incluye la detección de problemas de navegación, análisis de datos,
						testeos web/<span class="GramE">app</span>, etc.
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) Cumplimiento de una obligación legal
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) Interés legítimo propio (llevanza de nuestra empresa, proveer de
						seguridad nuestras redes, prevenir el fraude, etc.)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 9">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Sugerirle y recomendarle los productos y servicios que pudieren ser
						de interés
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) En interés legítimo (para hacer crecer nuestro negocio)
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 10">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Proveer de información personal a las autoridades o por
						requerimiento judicial
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Cumplimiento de una obligación legal
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 11">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Dotar de mayor seguridad nuestras instalaciones físicas (instalación
						de cámaras de CCTV/ video vigilancia, control de accesos)
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						En interés legítimo y en interés de terceros. Por
						<span class="GramE">ejemplo</span> para detectar la comisión de un
						acto dañino para nuestros empleados o clientes.
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 12">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Actualización y mejora de nuestros registros de clientes
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) En cumplimiento de una obligación legal
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) En ejecución de un contrato
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(3) En interés legítimo (para verificar que podemos seguir en
						contacto con nuestros clientes para cuestiones relacionadas con sus
						suscripciones, pedidos o productos adquiridos).
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 13">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						Garantizar la seguridad en el puesto de trabajo, administración de
						personal y empleabilidad de candidatos
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(1) En cumplimiento de una obligación legal
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 125%"
					>
						(2) En interés legítimo propio y de terceros. Para mejorar la
						experiencia de nuestros empleados en el ejercicio de sus funciones.
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 14">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							Ayuda en línea para la compra de productos o servicios
							-conversaciones instantáneas contextualizadas (
							<span class="SpellE">chatbot</span>)
						</span>
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							(1) Consentimiento expreso del interesado
						</span>
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							(2) Cumplimiento del contrato
						</span>
					</p>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							(3) Interés legítimo (mejora de nuestro servicio al cliente)
						</span>
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 15">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p class="MsoNormal">
						Responder a posibles reclamaciones sobre incidencias en el envío.
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							(1) Interés legítimo
						</span>
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 16">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p class="MsoNormal">
						Proporcionar hojas de reclamaciones a los usuarios que lo soliciten
						a través de atención al cliente (formulario de contacto, teléfono o
						correo electrónico)
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						align="left"
						style="text-align: left; line-height: 115%"
					>
						<span style="mso-fareast-language: ES-TRAD">
							(1) Obligación legal
						</span>
					</p>
				</td>
			</tr>
			<tr style="mso-yfti-irow: 17; mso-yfti-lastrow: yes">
				<td
					width="283"
					valign="top"
					style="
						width: 212.05pt;
						border: solid windowtext 1pt;
						border-top: none;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoNormal"
						style="
							mso-margin-top-alt: auto;
							mso-margin-bottom-alt: auto;
							line-height: 115%;
							background: white;
						"
					>
						<span style="color: black; mso-themecolor: text1">
							Seguimiento del procedimiento para la resolución de litigios en
							línea de la Comisión Europa, en particular bajo la plataforma ODR:
						</span>
					</p>
					<p
						class="MsoNormal"
						style="
							mso-margin-top-alt: auto;
							mso-margin-bottom-alt: auto;
							line-height: 115%;
							background: white;
						"
					>
						<span style="color: black; mso-color-alt: windowtext">
							<a href="http://ec.europa.eu/consumers/odr/">
								<span style="color: black; mso-themecolor: text1">
									http://ec.europa.eu/consumers/odr/
								</span>
							</a>
						</span>
						<u>
							<span style="color: black; mso-themecolor: text1"></span>
						</u>
					</p>
					<p
						class="MsoNormal"
						style="
							mso-margin-top-alt: auto;
							mso-margin-bottom-alt: auto;
							line-height: 115%;
							background: white;
						"
					>
						<span style="color: black; mso-themecolor: text1">
							Seguimiento de reclamaciones de usuarios y procedimientos ante
							Agencias de Consumo (registro con clave identificativa)
						</span>
						<span
							style="
								font-family: 'Calibri', sans-serif;
								mso-ascii-theme-font: minor-latin;
								mso-hansi-theme-font: minor-latin;
								mso-bidi-theme-font: minor-latin;
								color: black;
								mso-themecolor: text1;
							"
						></span>
					</p>
				</td>
				<td
					width="283"
					valign="top"
					style="
						width: 212.1pt;
						border-top: none;
						border-left: none;
						border-bottom: solid windowtext 1pt;
						border-right: solid windowtext 1pt;
						mso-border-top-alt: solid windowtext 0.5pt;
						mso-border-left-alt: solid windowtext 0.5pt;
						mso-border-alt: solid windowtext 0.5pt;
						padding: 0cm 5.4pt 0cm 5.4pt;
					"
				>
					<p
						class="MsoListParagraph"
						style="
							margin-left: 21pt;
							mso-add-space: auto;
							mso-list: l5 level1 lfo7;
							vertical-align: baseline;
						"
					>
						<span style="color: black; mso-themecolor: text1">
							Obligación legal&nbsp;
						</span>
					</p>
				</td>
			</tr>
		</table>
	</div>

	<p class="MsoNormal" style="line-height: 125%">
		<span
			style="font-family: 'Segoe UI', sans-serif; mso-fareast-language: ES-TRAD"
		></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<span
			style="font-family: 'Segoe UI', sans-serif; mso-fareast-language: ES-TRAD"
		></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>¿Con quién podríamos compartir sus datos personales?</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Es posible que debamos compartir su información personal, con:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="
			margin-left: 54pt;
			mso-add-space: auto;
			line-height: 125%;
			mso-list: l6 level1 lfo3;
		"
	>
		Terceras empresas que subcontratamos o proveedores de servicios.
		<span
			style="
				mso-fareast-font-family: 'Times New Roman';
				mso-fareast-theme-font: minor-fareast;
				color: black;
				mso-themecolor: text1;
			"
		></span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="
			margin-left: 54pt;
			mso-add-space: auto;
			line-height: 125%;
			mso-list: l6 level1 lfo3;
		"
	>
		Terceros que necesitamos para gestionar nuestro negocio.
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="
			margin-left: 54pt;
			mso-add-space: auto;
			line-height: 125%;
			mso-list: l6 level1 lfo3;
		"
	>
		Las entidades bancarias con las que trabajamos.
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Todos los proveedores o <span class="SpellE">subencargados</span> de
		tratamiento con los que trabajamos están vinculados contractualmente con
		nosotros. Podemos garantizar que cumplen con todas las medidas de seguridad
		necesarias para salvaguardar tu información personal, que utilizarán tus
		datos personales única y exclusivamente para las finalidades especificadas,
		de acuerdo con nuestras instrucciones. Si tiene alguna duda con los
		<span class="SpellE">subencargados</span> con los que trabajamos puede
		enviar un correo a
		<a href="mailto:privacy@ecoscooting.com">privacy@ecoscooting.com</a> y le
		facilitaremos un listado actualizado de nuestros
		<span class="SpellE">partners</span>.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		También compartiremos información personal con los cuerpos y fuerzas de
		seguridad cuando la ley nos obligue a ello.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Dónde alojamos su información personal?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Toda la información que nos proporcione, tanto a través de esta web como por
		otras vías o canales, será alojada en los servidores CLOUD de AMAZON WEB
		SERVICE y en los servidores CLOUD de ALIBABA. Dichos servidores están
		alojados dentro del Espacio Económico Europeo.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>Transferencia internacional de información personal</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Con el fin de poder dar servicio, en ocasiones necesitaremos transferir tus
		datos fuera del Espacio Económico Europeo (EEE). Por ejemplo:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="line-height: 125%; mso-list: l1 level1 lfo5"
	>
		Para comunicarnos con Ud. o con nuestros proveedores cuando se encuentren
		fuera de la UE;
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="line-height: 125%; mso-list: l1 level1 lfo5"
	>
		Cuando exista una dimensión internacional en los productos/servicios que te
		proveemos.<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Las transferencias internacionales de datos están sujetas a normas
		especiales regidas por los principios de las leyes de protección de datos.
		Esto significa que únicamente podremos transferir tus datos a países u
		organizaciones internacionales exteriores a la UE, cuando:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="line-height: 125%; mso-list: l3 level1 lfo4"
	>
		El país receptor es considerado como seguro por la autoridad competente en
		cuanto al nivel de protección que aplica sobre los datos personales.
		<span
			style="
				mso-fareast-font-family: 'Times New Roman';
				mso-fareast-theme-font: minor-fareast;
				color: black;
				mso-themecolor: text1;
			"
		></span>
	</p>

	<p
		class="MsoListParagraphCxSpMiddle"
		style="line-height: 125%; mso-list: l3 level1 lfo4"
	>
		Se han tomado todas las medidas pertinentes para garantizar la salvaguarda
		de la seguridad y el correcto desarrollo de tus derechos legales, así como
		la posibilidad de interponer reclamaciones.
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="line-height: 125%; mso-list: l3 level1 lfo4"
	>
		Exista una excepción aplicable según la ley de protección de datos.
		<span style="color: black; mso-themecolor: text1"></span>
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Durante cuánto tiempo conservaremos sus datos personales?
		</b>
	</p>

	<p
		style="
			margin-top: 0cm;
			margin-right: 0cm;
			margin-bottom: 7.5pt;
			margin-left: 0cm;
			text-align: justify;
			background: white;
		"
	>
		<span style="color: black; mso-themecolor: text1">
			Sus datos se conservarán mientras dure la relación comercial con nosotros
			o durante el tiempo necesario para cumplir con la finalidad para la que se
			recaban a no ser que ejercite con anterioridad su derecho de cancelación u
			oposición, o limitación al tratamiento. Transcurrido este período los
			datos serán suprimidos conforme a lo dispuesto en la normativa de
			protección de datos lo que implica su bloqueo, estando disponibles tan
			solo a solicitud de Jueces y tribunales, el Ministerio Fiscal o las
			Administraciones Públicas competentes (en especial Agencias en materia de
			consumo y en materia de protección de datos) durante el plazo de
			prescripción de las acciones que pudieran derivar para ser posteriormente
			eliminados.
		</span>
	</p>

	<p
		style="
			margin-top: 0cm;
			margin-right: 0cm;
			margin-bottom: 7.5pt;
			margin-left: 0cm;
			background: white;
		"
	></p>

	<p class="MsoNormal" style="line-height: 125%">
		Asimismo, le informamos que nuestras políticas de conservación de la
		información se ajustan a los plazos que marcan las distintas
		responsabilidades legales a efectos de prescripción:
	</p>

	<p
		class="MsoListParagraph"
		style="line-height: 125%; mso-list: l0 level1 lfo1"
	>
		<b style="mso-bidi-font-weight: normal">Como norma general:</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		En virtud de lo establecido en el artículo 30 del Código de Comercio, y
		salvo otros criterios, todos los documentos y/o información de la empresa se
		conservará durante
		<b style="mso-bidi-font-weight: normal">6 años</b>.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Esto afecta a toda la documentación contable, fiscal, laboral o mercantil,
		incluida la correspondencia.
	</p>

	<p
		class="MsoListParagraph"
		style="line-height: 125%; mso-list: l0 level1 lfo1"
	>
		<b style="mso-bidi-font-weight: normal">
			&nbsp;Plazos específicos:&nbsp;
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Nuestra empresa también debe marcar unos mínimos plazos en función de la
		tipología de datos que se trate y atendiendo a los distintos plazos de
		prescripción, que deberán conocer cada uno de los departamentos.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		En este cuadro se relacionan los plazos de prescripciones que afectan o
		pueden afectar a nuestra organización:
	</p>

	<p class="MsoNormal" style="line-height: 125%">&nbsp;</p>

	<table
		class="MsoNormalTable"
		border="1"
		cellspacing="0"
		cellpadding="0"
		width="592"
		style="
			width: 444pt;
			border-collapse: collapse;
			border: none;
			mso-border-alt: outset windowtext 1.5pt;
			mso-yfti-tbllook: 1184;
		"
	>
		<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					<b style="mso-bidi-font-weight: normal">Materia</b>
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					<b style="mso-bidi-font-weight: normal">Prescripción</b>
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					<b style="mso-bidi-font-weight: normal">Normativa</b>
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 1">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Laboral, a efectos de infracciones
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					3 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 4.1 RD 5/2000
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 2">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Seguridad Social, a efectos de infracciones
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					4 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 4.2 RD 5/2000
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 3">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Prevención de Riesgos Laborales, a efectos de infracciones
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					5 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 4.3 RD 5/2000
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 4">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Fiscal, a efectos de deudas tributarias
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					4 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 66 Ley 58/2003
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 5">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Fiscal, a efectos de comprobaciones de cuotas compensadas o
					deducciones aplicadas
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					10 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 66 bis Ley 58/2003
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 6">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Contable y mercantil
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					6 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 30 del CC
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 7">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Delitos contra Hacienda Pública y Seguridad Social
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					10 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 131 LO 10/1995
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 8">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Responsabilidad contractual
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					5 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 1968.2 del CC
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 9; mso-yfti-lastrow: yes">
			<td width="247" style="width: 185.15pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="left"
					style="text-align: left; line-height: 125%"
				>
					Responsabilidad extracontractual
				</p>
			</td>
			<td width="138" style="width: 103.55pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					1 años
				</p>
			</td>
			<td width="207" style="width: 155.3pt; padding: 0cm 0cm 0cm 0cm">
				<p
					class="MsoNormal"
					align="center"
					style="text-align: center; line-height: 125%"
				>
					Art. 1964.2 del CC
				</p>
			</td>
		</tr>
	</table>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		No será objeto de decisiones basadas en tratamientos automatizados que
		produzcan efectos sobre sus datos.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">Nuestras comunicaciones</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Toda la información personal que nos comunique se incorporará a nuestros
		sistemas de información. Si acepta la presente política de privacidad,
		significa que otorga a ECOSCOOTING el consentimiento expreso a que realice
		las siguientes actividades y/o acciones, mientras no nos indique lo
		contrario:
	</p>

	<p
		class="MsoListParagraphCxSpFirst"
		style="line-height: 125%; mso-list: l0 level1 lfo1"
	>
		A enviarle comunicaciones comerciales, promocionales y de marketing directo
		por cualquier medio de comunicación habilitado, para informarle de las
		actividades, servicios, promociones, publicidad, noticias, ofertas y demás
		información sobre los servicios y productos relacionados con nosotros y
		nuestro grupo.
	</p>

	<p
		class="MsoListParagraphCxSpLast"
		style="line-height: 125%; mso-list: l0 level1 lfo1"
	>
		La conservación de los datos durante los plazos previstos en las
		disposiciones aplicables.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b style="mso-bidi-font-weight: normal">
			¿Cómo dejar de recibir comunicaciones de marketing (
			<span class="SpellE">opt-out</span>)?
		</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		En cualquier momento puede revocar cualquier consentimiento expreso que nos
		haya otorgado para mandarle información comercial. Para ello puede solicitar
		su baja mediante la opción (
		<span class="SpellE">opt-out</span>) cuando esté habilitada en nuestra
		<span class="GramE">app</span>/web, o bien escribiéndonos un email con el
		asunto “baja” a privacy@ecoscooting.com
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		De acuerdo con la LSSICE, nunca realizamos SPAM, por lo tanto, no le
		enviaremos correos comerciales si no han sido solicitados o autorizados por
		Ud. No obstante, en todas nuestras comunicaciones, tendrá la posibilidad de
		revocar su consentimiento.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		No trataremos sus datos personales para ninguna otra finalidad de las
		descritas salvo por obligación legal o requerimiento judicial.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>
			Responsabilidad del
			<span class="GramE">
				usuario&nbsp;
				<span style="mso-bidi-font-weight: normal">&nbsp;-</span>
			</span>
		</b>
		<b style="mso-bidi-font-weight: normal"> Declaración de veracidad</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Al facilitarnos su información personal a través de canales electrónicos, el
		usuario declara que es mayor de 18 años y que todos los datos facilitados a
		ECOSCOOTING son verdaderos, exactos, completos y actualizados. A estos
		efectos, el usuario confirma que responde de la veracidad de los datos
		comunicados y que mantendrá convenientemente actualizada dicha información
		de modo que responda a su situación real, haciéndose responsable de los
		datos falsos e inexactos que pudiera proporcionar, así como de los daños y
		perjuicios, directos o indirectos, que pudieran derivarse.&nbsp;
	</p>

	<p class="MsoNormal"></p>

	<p class="MsoNormal">
		<b style="mso-bidi-font-weight: normal">Si nos envía su currículum</b>
	</p>

	<p class="MsoNormal">
		En el supuesto de que nos quiera hacer llegar su CV a través de nuestra web
		(apartado “trabaja con nosotros”) le informamos que los datos aportados
		serán tratados para hacerle partícipe de los procesos de selección que pueda
		haber, llevando a cabo un análisis de su perfil profesional con el objetivo
		de seleccionar la candidatura más adecuada para la eventual vacante.
	</p>

	<p class="MsoNormal">
		No aceptamos currículums remitidos por otros canales (por
		<span class="GramE">ejemplo</span> entregados en mano en soporte papel). En
		caso de producirse alguna modificación en los datos, le rogamos nos lo
		comunique por escrito cuanto antes, con el fin de mantener sus datos
		debidamente actualizados.
	</p>

	<p class="MsoNormal">
		Los currículums se conservarán durante el plazo máximo de
		<b style="mso-bidi-font-weight: normal">un año</b>, transcurrido el cual se
		procederá su destrucción segura y a la supresión de los todos los datos
		incluidos. Le garantizamos un total respeto a la confidencialidad. En este
		sentido, transcurrido el mencionado plazo, si desea continuar participando
		en los posibles procesos de selección, deberá remitir nuevamente su
		currículum.&nbsp;
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>¿Cómo mantenemos su información segura?</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Nos tomamos muy en serio la protección de sus datos. Por este motivo,
		garantizamos la implementación de las medidas de seguridad, controles y
		procedimientos de carácter físico, organizativo y tecnológico, apropiadas
		para evitar que su información se pierda accidentalmente, se utilice o se
		acceda a ella malintencionadamente.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Limitamos el acceso a sus datos a personas y entidades legitimadas para ello
		y nos aseguramos de formar a todo nuestro personal debidamente. Todos los
		intervinientes en el tratamiento de sus datos personales están sujetos al
		deber de confidencialidad.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Adicionalmente, aplicamos procedimientos técnicos para reaccionar ante
		cualquier sospecha que pudiera ser una brecha de seguridad de los datos. En
		caso de ser necesario, le notificaremos de
		<span class="GramE">ello</span> así como a la autoridad de control (la AEPD
		en España), de acuerdo con la normativa vigente.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>¿Cómo ejercer sus derechos ARCOLP?</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Tanto el RGPD como la norma de transposición a la legalidad española (la
		LOPDGDD), le garantiza el ejercicio de los siguientes derechos. Los puede
		ejercer en cualquier momento y siempre de forma gratuita:
	</p>

	<table
		class="MsoTableGrid"
		border="1"
		cellspacing="0"
		cellpadding="0"
		style="
			border-collapse: collapse;
			mso-table-layout-alt: fixed;
			border: none;
			mso-border-alt: solid windowtext 0.5pt;
			mso-yfti-tbllook: 1696;
			mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
		"
	>
		<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal">Derecho de acceso</b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-left: none;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a recibir copia de su información personal.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 1">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal"> Derecho de rectificación </b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a solicitar la corrección de errores en la información
					personal.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 2">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal">
						Derecho de cancelación/supresión (derecho al olvido)
					</b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a solicitar que eliminemos su información personal – en
					algunas situaciones.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 3">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal">Derecho de limitación</b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a solicitar la restricción del tratamiento de sus datos.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 4">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal">Derecho de oposición</b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a oponerse a:
				</p>
				<p class="MsoNormal" style="line-height: 125%">
					-al tratamiento de tus datos para marketing directo (incluido la
					creación de perfiles).
				</p>
				<p class="MsoNormal" style="line-height: 125%">
					-en ciertas circunstancias a que sigamos tratando sus datos. Por
					ejemplo, el tratamiento efectuado en base a nuestro interés legítimo.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 5">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal"> Derecho de portabilidad </b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					El derecho a recibir su información personal en una forma
					estructurada, en un formato legible y/o transmitir estos datos a un
					tercero – en situaciones concretas.
				</p>
			</td>
		</tr>
		<tr style="mso-yfti-irow: 6; mso-yfti-lastrow: yes">
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border: solid windowtext 1pt;
					border-top: none;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					<b style="mso-bidi-font-weight: normal">
						Decisiones individuales automatizadas
					</b>
				</p>
			</td>
			<td
				width="283"
				valign="top"
				style="
					width: 212.25pt;
					border-top: none;
					border-left: none;
					border-bottom: solid windowtext 1pt;
					border-right: solid windowtext 1pt;
					mso-border-top-alt: solid windowtext 0.5pt;
					mso-border-left-alt: solid windowtext 0.5pt;
					mso-border-alt: solid windowtext 0.5pt;
					padding: 0cm 5.4pt 0cm 5.4pt;
				"
			>
				<p class="MsoNormal" style="line-height: 125%">
					Derecho a no ser objeto de una decisión basada únicamente en el
					tratamiento automatizado, incluida la elaboración de perfiles, que
					produzca efectos jurídicos o afecte significativamente.
				</p>
				<p class="MsoNormal" style="line-height: 125%"></p>
			</td>
		</tr>
	</table>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		Para ejercer cualquiera de los derechos mencionados, por favor, puede
		escribirnos con su solicitud al correo electrónico específicamente
		habilitado al efecto: privacy@ecoscooting.com
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		También puede contactar directamente con nuestro
		<span class="GramE">Delegado</span> de Protección de Datos:
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<span
			style="
				color: black;
				mso-color-alt: windowtext;
				border: none windowtext 1pt;
				mso-border-alt: none windowtext 0cm;
				padding: 0cm;
				background: white;
			"
		>
			xavi@aurisadvocats.com
		</span>
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		Deberá adjuntar a su solicitud información sobre qué necesita con exactitud
		y, en todo caso, acreditación de su identidad, válida en derecho.
	</p>

	<p class="MsoNormal" style="line-height: 125%"></p>

	<p class="MsoNormal" style="line-height: 125%">
		<b>La autoridad de control en materia de protección de datos</b>
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		Esperamos poder resolver cualquier cuestión o duda que pueda preocuparle
		<span class="GramE">en relación a</span> su información personal. Pero si
		desea interponer una queja ante la autoridad competente, tiene derecho a
		ello.
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		En España la máxima autoridad en materia de protección de datos es la
		Agencia Española de Protección de Datos (AEPD).
	</p>

	<p class="MsoNormal" style="line-height: 125%">
		<a href="https://www.aepd.es/es">https://www.aepd.es/es</a> - Tel: 91 266 35
		17.
	</p>

	<p class="MsoNormal"></p>

	<p class="MsoNormal">
		<b style="mso-bidi-font-weight: normal">
			Cambios en la presente política de privacidad
		</b>
	</p>

	<p class="MsoNormal">
		ECOSCOOTING se reserva el derecho a modificar la presente política para
		adaptarla a novedades legislativas o jurisprudenciales.
	</p>

	<p class="MsoNormal"></p>
</div>

`;

export default function Privacidad() {
	return (
		<div>
			<div className="container">
				<Row>
					<Col lg={3} sm={24} xs={24} span={8}></Col>
					<Col lg={18} sm={24} xs={24}>
						<Link to="/">
							<img
								alt="Ecoscooting Delivery"
								src={Logo}
								style={{ width: "30%", marginBottom: "50px" }}
							/>
						</Link>
						<div
							className="politicas"
							dangerouslySetInnerHTML={{ __html: htmlContent }}
						></div>
					</Col>
					<Col lg={3} sm={24} xs={24} span={8}></Col>
				</Row>
			</div>
			<Footer />
		</div>
	);
}
