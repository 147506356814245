import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'antd';
import delivery from './images/delivery.svg';
import food from './images/ecommerce.svg';
import oclock from './images/time-management.svg';
import { useTranslation } from "react-i18next";

export default function Services({ isMobile }) {
  const [t, i18n] = useTranslation('global');
  return (
    <div id="services">
      <div className="c-services">
        <div>
          <h2 className="title-services">{t("Services.title-services")}</h2>
        </div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
            <Card
              cover={
                <img
                  alt="example"
                  src={delivery}
                  className="card-img"
                />
              }
              bordered={false}
            >
              <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={14} xl={14} span={8}>
                  <h2>{t("Services.same-day")}</h2>
                  <p>{t("Services.same-day-text")}</p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
            <Card
              cover={
                <img
                  alt="example"
                  className="card-img"
                  src={oclock}
                />
              }
              bordered={false}
            >
              <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={14} xl={14} span={8}>
                  <h2>{t("Services.next-day")}</h2>
                  <p>{t("Services.next-day-text")}.{' '}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} span={8}>
            <Card
              cover={
                <img
                  alt="example"
                  src={food}
                  style={{ width: '53.5%', marginLeft: '20%' }}
                />
              }
              bordered={false}
            >
              <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={14} xl={14} span={8}>
                  <h2>{t("Services.supermarket")}</h2>
                  <p>{t("Services.supermarket-text")}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
Services.propTypes = {
  isMobile: PropTypes.bool,
};
