import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Language() {
  const [t, i18n] = useTranslation('global');

  return (
    <div className="select-languaje">
      <img
        src="https://img.icons8.com/office/30/000000/portugal.png"
        onClick={() => i18n.changeLanguage('pt')}
      />
      <img
        src="https://img.icons8.com/office/30/000000/usa.png"
        onClick={() => i18n.changeLanguage('en')}
      />
      <img
        src="https://img.icons8.com/office/30/000000/spain-2.png"
        onClick={() => i18n.changeLanguage('es')}
      />
    </div>
  );
}
